<template>
  <div
    :class="['richTextRenderer', `richTextRenderer--${getClassName}`]"
    v-html="getHtmlString"
  />
</template>

<script lang="ts">
import { INLINES, Document } from '@contentful/rich-text-types'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

export default {
  name: 'RichTextRenderer',
  props: {
    name: {
      type: String,
      required: true
    },
    content: {
      type: Object as PropType<Document>,
      required: true
    }
  },
  setup(props) {
    const { $config } = useNuxtApp()
    const { slugify } = useUi()

    const getHtmlString = computed<string>(() => {
      const o = {
        renderNode: {
          [INLINES.HYPERLINK]: (node) =>
            `<a
              href=${node.data.uri}
              target={${
                node.data.uri.startsWith($config.public.baseUrl)
                  ? '_self'
                  : '_blank'
              }}
              rel={${
                node.data.uri.startsWith($config.public.baseUrl)
                  ? ''
                  : 'noopener noreferrer'
              }}
            >${node.content[0].value}</a>`
        }
      }

      return documentToHtmlString(props.content, o)
    })

    const getClassName = (): string => {
      return slugify(props.name)
    }

    return {
      getHtmlString,
      getClassName
    }
  }
}
</script>

<style lang="scss">
@import './assets/styles/tools/_typography.scss';
.richTextRenderer {
  a {
    @extend .inline-link !optional;
    @extend .bold !optional;
  }
}

.richTextRenderer--offerCard {
  p {
    color: $black--light;
  }
}
</style>
